<template>
  <div class="container mt-4">
    <div class="d-flex justify-content-between align-items-center mb-3">
        <h2>Список пользователей</h2>
    </div>

    <div v-if="users.length > 0">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>ID</th>
            <th>Имя</th>
            <th>Email</th>
            <th>ID табеля</th>
            <th>Месячный лимит</th>
            <th>Потрачено, USD</th>
            <th class="text-end">Действия</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="userItem in users" :key="userItem.id">
            <td>{{ userItem.id }}</td>
            <td>{{ userItem.name }}</td>
            <td>{{ userItem.email }}</td>
            <td>{{ userItem.table_id }}</td>
            <td>{{ userItem.monthly_limit }}</td>
            <td>{{ userItem.total_cost.toFixed(2) }}</td>
            <td class="text-end">
              <router-link :to="{ name: 'UserDetailsView', params: { id: userItem.id } }" class="btn btn-info btn-sm me-2">
                Подробнее
              </router-link>
              <button class="btn btn-warning btn-sm me-2" @click="editUser(userItem)">Редактировать</button>
              <button class="btn btn-danger btn-sm" @click="confirmDelete(userItem)">Удалить</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Модалка для создания и редактирования кейса -->
    <div class="modal fade" id="userModal" tabindex="-1" aria-labelledby="userModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ editingUser ? 'Редактировать пользователя' : 'Создать пользователя' }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="submitUser">
              <div class="mb-3">
                <label for="title" class="form-label">Email</label>
                <input v-model="userData.email" type="text" class="form-control" id="title" required />
              </div>
              <div class="mb-3">
                <label for="description" class="form-label">ID табеля</label>
                <textarea v-model="userData.table_id" class="form-control" id="description" required></textarea>
              </div>
              <div class="mb-3">
                <label for="description" class="form-label">Месячный лимит</label>
                <input type="number" v-model="userData.monthly_limit" class="form-control" id="description" required>
              </div>
              <div class="d-flex">
                <button type="submit" class="btn btn-primary ms-auto">{{ editingUser ? 'Сохранить' : 'Создать' }}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Уведомления об ошибках или успешных действиях -->
    <div v-if="toastMessage" class="alert alert-info position-fixed bottom-0 end-0 p-3">
      {{ toastMessage }}
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap';
import axiosInstance from '../services/axiosInstance';

export default {
  data() {
    return {
      users: [],
      userData: {
        title: '',
        table_id: '',
        total_cost: 0,
        monthly_limit: 0,
      },
      editingUser: null,
      toastMessage: '',
    };
  },
  methods: {
    async fetchUsers() {
      try {
        const response = await axiosInstance.get('/users');
        this.users = response.data;
      } catch (error) {
        this.showToast('Ошибка при загрузке пользователей');
      }
    },
    openCreateModal() {
      this.userData = {
        name: '',
        table_id: '',
        monthly_limit: '',
        email: ''
      };
      this.editingUser = null;
      const modal = new Modal(document.getElementById('userModal'));
      modal.show();
    },
    editUser(userItem) {
      this.userData = { ...userItem };
      this.editingUser = userItem.id;
      const modal = new Modal(document.getElementById('userModal'));
      modal.show();
    },
    async submitUser() {
      try {
        if (this.editingUser) {
          await axiosInstance.put(`/users/${this.editingUser}`, this.userData);
          this.showToast('Пользователь обновлен');
        } else {
          await axiosInstance.post('/users', this.userData);
          this.showToast('Пользователь создан');
        }
        this.fetchUsers();
        const modal = Modal.getInstance(document.getElementById('userModal'));
        modal.hide();
      } catch (error) {
        this.showToast('Ошибка при сохранении пользователя');
      }
    },
    confirmDelete(userItem) {
      if (confirm(`Вы уверены, что хотите удалить пользователя "${userItem.title}"?`)) {
        this.deleteUser(userItem.id);
      }
    },
    async deleteUser(userId) {
      try {
        await axiosInstance.delete(`/users/${userId}`);
        this.showToast('Пользователь удален');
        this.fetchUsers();
      } catch (error) {
        this.showToast('Ошибка при удалении пользователя');
      }
    },
    showToast(message) {
      this.toastMessage = message;
      setTimeout(() => {
        this.toastMessage = '';
      }, 3000);
    },
    truncateText(text, length) {
      return text.length > length ? text.substring(0, length) + '...' : text;
    },
  },
  mounted() {
    this.fetchUsers();
  },
};
</script>

<style>
.table td {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.alert {
  z-index: 1050;
}
</style>
