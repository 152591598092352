<template>
  <div class="container mt-4">
    <h2 class="text-center">Детали кейса {{ caseData.id }}: "{{ caseData.title }}"</h2>
    <div v-if="caseData">
      <h3 class="mt-5">Описание: </h3>
      <div style="white-space: pre-line;">{{caseData.description}}</div>
      <h3 class="mt-5">Вопросы для фидбека: </h3>
      <div style="white-space: pre-line;">{{caseData.questions}}</div>

      <div class="d-flex justify-content-between align-items-center mt-5">
        <h3>Участники</h3>
        <button @click="openMemberModal" class="btn btn-success mb-3">Добавить участника</button>
      </div>

      <table class="table">
        <thead>
          <tr>
            <th>Аватар</th>
            <th>Имя</th>
            <th>Должность</th>
            <th>Инструкции</th>
            <th>Инструкции для человека</th>
            <th class="text-end">Действия</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="member in caseData.members" :key="member.id">
            <td>
              <img v-if="member.avatar_url" :src="member.avatar_url" alt="Avatar" class="img-thumbnail avatar-thumbnail" />
            </td>
            <td>{{ member.name }}</td>
            <td style="max-width: 300px;">{{ member.position }}</td>
            <td style="max-width: 300px;">{{ member.instructions }}</td>
            <td style="max-width: 300px;">{{ member.human_instructions }}</td>
            <td>
              <button @click="editMember(member)" class="btn btn-warning btn-sm me-2">Редактировать</button>
              <button @click="confirmDelete(member)" class="btn btn-danger btn-sm">Удалить</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Модалка для добавления/редактирования участника -->
    <div class="modal fade" id="memberModal" tabindex="-1" aria-labelledby="memberModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ isEditMode ? 'Редактировать участника' : 'Добавить участника' }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="submitMember">
              <div class="mb-3">
                <label for="name" class="form-label">Имя</label>
                <input v-model="memberData.name" type="text" class="form-control" id="name" required />
              </div>
              <div class="mb-3">
                <label for="position" class="form-label">Должность</label>
                <input v-model="memberData.position" type="text" class="form-control" id="position" required />
              </div>
              <div class="mb-3">
                <label for="instructions" class="form-label">Инструкции</label>
                <textarea v-model="memberData.instructions" class="form-control" id="instructions"></textarea>
              </div>
              <div class="mb-3">
                <label for="human_instructions" class="form-label">Инструкции для человека</label>
                <textarea v-model="memberData.human_instructions" class="form-control" id="human_instructions"></textarea>
              </div>
              <div class="mb-3">
                <label for="avatar" class="form-label">Загрузить аватар</label>
                <input type="file" class="form-control" id="avatar" accept="image/png, image/jpg, image/jpeg, image/webp" @change="onFileChange" ref="fileInput" />
              </div>
              <div class="d-flex">
                <button type="submit" class="btn btn-primary mt-3 ms-auto">{{ isEditMode ? 'Обновить' : 'Добавить' }} участника</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Модалка подтверждения удаления -->
    <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Подтвердите удаление</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            Вы уверены, что хотите удалить участника <strong>{{ memberToDelete?.name }}</strong>?
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Отмена</button>
            <button type="button" class="btn btn-danger" @click="deleteMember">Удалить</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Уведомления -->
    <div v-if="toastMessage" class="alert alert-info position-fixed bottom-0 end-0 p-3">
      {{ toastMessage }}
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap';
import axiosInstance from '../services/axiosInstance';

export default {
  props: ['id'],
  data() {
    return {
      caseData: null,
      memberData: {
        name: '',
        position: '',
        instructions: '',
        human_instructions: '',
      },
      avatarFile: null,
      avatarUrl: null,
      isEditMode: false, // Для определения режима редактирования
      memberToDelete: null, // Для хранения участника, которого нужно удалить
      toastMessage: '',
    };
  },
  methods: {
    async fetchCaseDetails() {
      try {
        const response = await axiosInstance.get(`/cases/${this.id}`);
        this.caseData = response.data;
      } catch (error) {
        this.showToast('Ошибка при загрузке данных кейса');
      }
    },
    openMemberModal() {
      this.isEditMode = false;
      this.memberData = { name: '', position: '', instructions: '', human_instructions: '' };
      this.avatarFile = null;
      this.avatarUrl = null;
      const modal = new Modal(document.getElementById('memberModal'));
      modal.show();
    },
    editMember(member) {
      this.isEditMode = true;
      this.memberData = { ...member };
      this.avatarFile = null;
      this.avatarUrl = null;
      this.$refs.fileInput.value = '';
      const modal = new Modal(document.getElementById('memberModal'));
      modal.show();
    },
    confirmDelete(member) {
      this.memberToDelete = member;
      const modal = new Modal(document.getElementById('deleteModal'));
      modal.show();
    },
    async deleteMember() {
      try {
        await axiosInstance.delete(`/case_members/${this.memberToDelete.id}`);
        this.showToast('Участник удален');
        this.fetchCaseDetails();
        const modal = Modal.getInstance(document.getElementById('deleteModal'));
        modal.hide();
      } catch (error) {
        this.showToast('Ошибка при удалении участника');
      }
    },
    onFileChange(event) {
      const file = event.target.files[0];
      const allowedTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/webp'];

      if (file && allowedTypes.includes(file.type)) {
        this.avatarFile = file;
        this.avatarUrl = URL.createObjectURL(file);
      } else {
        this.showToast('Неподдерживаемый формат файла. Используйте PNG, JPG, JPEG или WEBP.');
        this.avatarFile = null;
        this.avatarUrl = null;
      }
    },
    async submitMember() {
      try {
        const memberPayload = { ...this.memberData, case_id: this.id };

        const formData = new FormData();
        for (const key in memberPayload) {
          formData.append(key, memberPayload[key]);
        }
        if (this.avatarFile) {
          formData.append('avatar', this.avatarFile);
        }

        if (this.isEditMode) {
          await axiosInstance.put(`/case_members/${this.memberData.id}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          this.showToast('Участник обновлен');
        } else {
          await axiosInstance.post('/case_members', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          this.showToast('Участник добавлен');
        }

        this.fetchCaseDetails();
        const modal = Modal.getInstance(document.getElementById('memberModal'));
        modal.hide();
      } catch (error) {
        this.showToast('Ошибка при сохранении участника');
      }
    },
    showToast(message) {
      this.toastMessage = message;
      setTimeout(() => {
        this.toastMessage = '';
      }, 3000);
    },
  },
  mounted() {
    this.fetchCaseDetails();
  },
};
</script>

<style>
.alert {
  z-index: 1050;
}
.avatar-preview img,
.avatar-thumbnail {
  width: 50px;
  height: 50px;
  object-fit: cover;
}
</style>
