<template>
  <div class="container mt-4">
    <div class="d-flex justify-content-between align-items-center mb-3">
        <h2>Детализация пользователя {{this.usage_details.user_name}}</h2>
    </div>
    <div v-if="this.usage_details.daily_costs.length > 0">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>#</th>
            <th>Дата</th>
            <th>Потрачено, USD</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in this.usage_details.daily_costs" :key="item.date">
            <td>{{ index + 1 }}</td>
            <td>{{ item.date }}</td>
            <td>{{ item.total_cost.toFixed(2) }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Уведомления об ошибках или успешных действиях -->
    <div v-if="toastMessage" class="alert alert-info position-fixed bottom-0 end-0 p-3">
      {{ toastMessage }}
    </div>
  </div>
</template>

<script>
import axiosInstance from '../services/axiosInstance';

export default {
  props: ['id'],
  data() {
    return {
      usage_details: {
        user_name: "",
        daily_costs: []
      },
      toastMessage: '',
    };
  },
  methods: {
    async fetchUserDetails() {
      try {
        const response = await axiosInstance.get(`/users/${this.id}/usage-details`);
        this.usage_details = response.data;
      } catch (error) {
        this.showToast('Ошибка при загрузке деталей пользователя');
      }
    },
    showToast(message) {
      this.toastMessage = message;
      setTimeout(() => {
        this.toastMessage = '';
      }, 3000);
    },
  },
  mounted() {
    this.fetchUserDetails();
  },
};
</script>

<style>
.table td {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.alert {
  z-index: 1050;
}
</style>
